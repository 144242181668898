<template>
<HeaderComponent/>
<SectionView/>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import SectionView from '../views/SectionView.vue';

  export default {
    components: { HeaderComponent, SectionView }
}
</script>

<style>

</style>